import React, { Component } from 'react'
import ProgramPage from '../../Component/Pages/Program'

export default class Program extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    const {programRequest} = this.props
    programRequest()
  }
  

  render() {
    const {Programdata, testrequest} =this.props
    return (
      <div>
        <ProgramPage 
          Programdata={Programdata}
          testrequest={testrequest}
        />
      </div>
    )
  }
}
