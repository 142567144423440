import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { CASES_FETCH_REQUEST } from './actionType';
import { casesRequestsuccess, casesRequestfailure } from './action';
import { Api } from '../../common/Api';

function* casesSaga(action) {
    try {
        const response = yield call(axios.get, `${Api.Apiurl}/external/home/case`, {
            params: action.payload
        });
        yield put(casesRequestsuccess(response.data));
    } catch (error) {
        yield put(casesRequestfailure(error.message));
    }
}

export function* caserootSaga() {
    yield takeEvery(CASES_FETCH_REQUEST, casesSaga);
}
