import React, { useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import '../Style/Modules.css'

function Modules({
  course,
  isLoading,
}) {
  const [courseData, setcourseData] = useState([]);
  const [expandedNews, setExpandedNews] = useState({});

  useEffect(() => {
    if (course !== undefined) {
      setcourseData(course);
    }
  }, [course]);

  console.log('coursedata', courseData)

  const toggleReadMore = (index) => {
    setExpandedNews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (isLoading) {
    return (
      <div>Loading....</div>
    )
  }

  return (
    <div className="modules-container">
      <div className="modules-grid">
        {courseData.map((course, index) => (
          <div key={index} className="module-card">
            <img
              src={course.thumbnail_url || 'https://via.placeholder.com/150'}
              alt={`${module.module_title} Thumbnail`}
              className="module-thumbnail"
            />
            <div style={{ minHeight: '30px' }}>
              <h4>{course.name}</h4>
            </div>
            <div style={{ minHeight: '100px' }}>
              <p className="news-description-main">
                {expandedNews[index] ? course.module_description : `${course.module_description.slice(0, 50)}...`}
                <button className="read-more-button-main" onClick={() => toggleReadMore(index)}>
                  {expandedNews[index] ? 'Read Less' : 'Read More'}
                </button>
              </p>
            </div>
            <div className="progress-bar">
              <div className="progress" style={{ width: '40%' }}></div>
            </div>
            <div className="module-footer">
              <div>
                <FaClock className="icon" />
                {/* {module.module_type} */}
              </div>
              <div>
                <FaClock className="icon" />
                {course.module_type}
              </div>
            </div>
            <div style={{ marginBottom: '15%' }}>
              <button className="enroll-button">Enroll Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Modules;
