import { NEWS_FEED_REQUEST, NEWS_FEED_FETCH_FAILURE, NEWS_FEED_FETCH_SUCCESS } from './actionTypes';

const initialState = {
    fetchdata: false,
    newsFeed: { data: [] },
    error: null,
    isLoading: false
};

const newsfeedReducer = (state = initialState, action) => {
    switch(action.type) {
        case NEWS_FEED_REQUEST:
            return {...state, isLoading: true};
        case NEWS_FEED_FETCH_SUCCESS:
            return {
                ...state,
                fetchdata: true,
                newsFeed: action.payload,
                error: null,
                isLoading: false
            };
        case NEWS_FEED_FETCH_FAILURE:
            return {
                ...state,
                fetchdata: false,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default newsfeedReducer;
