import { all, fork } from 'redux-saga/effects';
import * as  modulerootSaga  from './Container/Modules/Module-saga';
import * as newsrootSaga from './Container/NewsFeed/Newsfeed-saga';
import * as courserootSaga from './Container/Courses/Courses-saga'
import * as casesrootSaga from './Container/Cases/Case-saga'
import * as webinarrootSaga from './Container/Webinars/Webinar-saga'
import * as quizesaga from './Container/quize/Quize-saga'
import * as pollSaga from './Container/polls/Polls-saga'
import * as resourceSaga from './Container/Resource/Resource-saga'
import * as loginSaga from './Container/Login/Login-Saga'
import * as Programsaga from './Container/program/Program-saga'
import * as Testsaga from './Container/Test/Test-saga'

export function* rootSaga() {
    const modulesaga = Object.values(modulerootSaga).map((saga) => {
        return fork(saga)
      })

      const newsSaga  = Object.values(newsrootSaga).map((saga) => {
        return fork(saga)
      })

      const courseSaga  = Object.values(courserootSaga).map((saga) => {
        return fork(saga)
      })

      const caseSage = Object.values(casesrootSaga).map((saga) => {
        return fork(saga)
      })
      const webinarSage = Object.values(webinarrootSaga).map((saga) => {
        return fork(saga)
      })

      const quizSage = Object.values(quizesaga).map((saga) => {
        return fork(saga)
      })

      const pollSage = Object.values(pollSaga).map((saga) => {
        return fork(saga)
      })

      const ResourceSage = Object.values(resourceSaga).map((saga) => {
        return fork(saga)
      })

      const LoginSage = Object.values(loginSaga).map((saga) => {
        return fork(saga)
      })
      const programSage = Object.values(Programsaga).map((saga) => {
        return fork(saga)
      })

      const testsaga = Object.values(Testsaga).map((saga) => {
        return fork(saga)
      })

      
    yield all([
        ...modulesaga,
        ...newsSaga,
        ...courseSaga,
        ...caseSage,
        ...webinarSage,
        ...quizSage,
        ...pollSage,
        ...ResourceSage,
        ...LoginSage,
        ...programSage,
        ...testsaga
    ]);
}
