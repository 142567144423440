import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { BiBell } from 'react-icons/bi';
import '../Style/Header.css';
import logo from '../../assets/decedu_logo.png';
import women from '../../assets/women.png';
import Banner from '../Banner/Banner';
import { connect } from 'react-redux';
import { logoutRequest } from '../../Container/Login/action';
import Modal from '../../common/Modal';
import { BannerRequest } from '../../Container/Login/action';

const Header = ({
  logoutRequest,
  Bannerrequest,
  Bannerdata,
  user
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, settoken] = useState(false)
  const [BannerData, setBannerData] = useState([])
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    if (user === null) {
      navigate('/login');
    }
    const authtoken = localStorage.getItem('token')
    if (authtoken !== null) {
      settoken(true)
    }
    Bannerrequest()
  }, [user, navigate, token]);

  useEffect(() => {
    if (Bannerdata !== undefined) {
      setBannerData(Bannerdata[0].Banners)
    }
  }, [Bannerdata])

  const search = (term) => {
    console.log('Searching for:', term);
  };

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  const confirmLogout = () => {
    logoutRequest();
    // if (user === null) {
    //   navigate('/login');
    // }
    setIsModalOpen(false);
  };

  const cancelLogout = () => {
    setIsModalOpen(false);
  };

  const handleLogin = () => {
    navigate('/login')
  }

  const isActive = (path) => location.pathname === path ? 'active' : '';
  const bannershow = ['/'].includes(location.pathname);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
        <div className="search-bar">
          <div className="search-container">
            <input
              className="header-input"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search here"
            />
            <span className="search-btn" onClick={() => search(searchTerm)}>
              <FaSearch />
            </span>
          </div>
        </div>
        <img onClick={() => { navigate('/') }} src={logo} width="10%" alt='logo' />
        <nav style={{ alignSelf: 'center', marginTop: '2%' }}>
          <ul style={{ listStyleType: 'none', display: 'flex', gap: '1rem' }}>
            <li className={isActive('/')}>
              <a href="/">Home</a>
            </li>
            <li className={isActive('/program')}>
              <a href="/program">Program</a>
            </li>
          </ul>
        </nav>
        {token && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <div className="icon-container">
              <BiBell size={20} />
            </div>
            <div className="profile-pic-container">
              <img src={women} className="profile-pic" alt='women' />
            </div>
            <div className="logout-container">
              <span onClick={handleLogout}>Logout</span>
            </div>
          </div>
        )}
        {!token && (
          <div style={{ width: '10%', textAlign: 'center' }} className="logout-container">
            <span onClick={handleLogin}>Login</span>
          </div>
        )}
      </div>
      {bannershow && <Banner BannerData={BannerData}/>}
      <Modal isOpen={isModalOpen} onClose={cancelLogout}>
        <p>Are you sure you want to logout?</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10%' }}>
          <button className='logout-yes-btn' onClick={confirmLogout}>Yes</button>
          <button className='logout-no-btn' onClick={cancelLogout}>No</button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log('user', state.login.user)
  return {
    user: state.login.user,
    Bannerdata:state.login.banner.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: () => dispatch(logoutRequest()),
    Bannerrequest: (params) => dispatch(BannerRequest(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
