import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import NewsfeedReducer from '../Container/NewsFeed/NewsFeed-reducer';
import { rootSaga } from '../Root';
import createSagaMiddleware from 'redux-saga'
import modulereducer from '../Container/Modules/Module-reducer';
import course from '../Container/Courses/Courses-reducer';
import cases from '../Container/Cases/Case-reducer';
import webinar from '../Container/Webinars/Webinar-reducer';
import quize from '../Container/quize/Quize-reducer';
import Polls from '../Container/polls/Polls-reducer';
import Resource from '../Container/Resource/Resource-reducer';
import login from '../Container/Login/Login-reducer'
import Program from '../Container/program/Program-reducer'
import Test from '../Container/Test/Test-reducer';


const rootReducer = combineReducers({
    newsfeed : NewsfeedReducer,
    module : modulereducer,
    course,
    cases,
    webinar,
    quize,
    Polls,
    Resource,
    login,
    Program,
    Test
});

const sagamedileware = createSagaMiddleware()
const store = createStore(rootReducer, applyMiddleware(thunk, sagamedileware)) ;

sagamedileware.run(rootSaga)

export default store;


