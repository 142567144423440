import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Header from '../Component/Pages/Header';
import NewsFeedRedux from '../Container/NewsFeed/NewsFeed-redux';
import ModuleRedux from '../Container/Modules/Module-redux';
import CoursesRedux from '../Container/Courses/Courses-redux';
import CaseRedux from '../Container/Cases/Case-redux';
import WebinarRedux from '../Container/Webinars/Webinar-redux';
import QuizeRedux from '../Container/quize/Quize-redux';
import PollsRedux from '../Container/polls/Polls-redux';
import ResourceRedux from '../Container/Resource/Resource-redux';
import Home from '../Component/Pages/Home';
import Sidenavbar from '../Component/Navbar/Sidenavbar';
// import Program from '../Component/Pages/Program';
import LoginRedux from '../Container/Login/Login-redux';
import ProgramRedux from '../Container/program/Program-redux';
import Footer from '../Component/Pages/Footer/Footer';
// import Test from '../Component/Pages/Test';
import TestRedux from '../Container/Test/Test-redux';

function Navigation() {
    const location = useLocation();
    const navbarshow = !['/', '/program', '/login'].includes(location.pathname)
    const headershow = !['/login'].includes(location.pathname)
    const isAuthenticated = localStorage.getItem('token');
    return (
        <div style={{ height: !headershow ? '' : '100vh' }}>
            <div style={{ backgroundColor: '#1C75BC', height: '4%', width: '100%' }}>
            </div>
            <div style={{
                width: !headershow ? '100%' : '82%',
                margin: '0 auto'
            }}>
                {headershow && (
                    <Header />
                )}
                <div style={{
                    display: !navbarshow ? 'block' : 'flex',
                    gap: !navbarshow ? '0' : '2%'
                }}>
                    {navbarshow  && (
                        <Sidenavbar />
                    )}
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/login' element={<LoginRedux />} />
                        {/* Protected routes */}
                        <Route path='/newsfeed' element={isAuthenticated ? <NewsFeedRedux /> : <Navigate to="/" replace />} />
                        <Route path='/modules' element={isAuthenticated ? <ModuleRedux /> : <Navigate to="/" replace />} />
                        <Route path='/course' element={isAuthenticated ? <CoursesRedux /> : <Navigate to="/" replace />} />
                        <Route path='/case' element={isAuthenticated ? <CaseRedux /> : <Navigate to="/" replace />} />
                        <Route path='/webinar' element={isAuthenticated ? <WebinarRedux /> : <Navigate to="/" replace />} />
                        <Route path='/quiz' element={isAuthenticated ? <QuizeRedux /> : <Navigate to="/" replace />} />
                        <Route path='/poll' element={isAuthenticated ? <PollsRedux /> : <Navigate to="/" replace />} />
                        <Route path='/resource' element={isAuthenticated ? <ResourceRedux /> : <Navigate to="/" replace />} />
                        <Route path='/program' element={isAuthenticated ? <ProgramRedux /> : <Navigate to="/" replace />} />
                        <Route path='/test' element={isAuthenticated ? <TestRedux /> : <Navigate to="/" replace />} />
                    </Routes>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Navigation;
