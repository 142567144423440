import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { COURSES_FETCH_REQUEST } from './actionType';
import { coursesRequestsuccess, coursesRequestfailure } from './action';
import { Api } from '../../common/Api';

function* courseSaga(action) {
    try {
        const response = yield call(axios.get, `${Api.Apiurl}/external/home/module`, {
            params: action.payload
        });
        yield put(coursesRequestsuccess(response.data));
    } catch (error) {
        yield put(coursesRequestfailure(error.message));
    }
}

export function* CourserootSaga() {
    yield takeEvery(COURSES_FETCH_REQUEST, courseSaga);
}
