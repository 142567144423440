import { POLLS_DATA_FETCH_REQUEST, POLLS_DATA_FETCH_SUCCESS, POLLS_DATA_FETCH_FAILTURE } from "./actionType";

const initialState = {
    fetchdata: false,
    Poll:[],
    error: null,
    isLoading: false
};


const Polls = (state = initialState, action) => {
    switch(action.type) {
        case POLLS_DATA_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case POLLS_DATA_FETCH_SUCCESS:
            return {
                ...state,
                fetchdata: true,
                Poll: action.payload,
                error: null,
                isLoading: false
            };
        case POLLS_DATA_FETCH_FAILTURE:
            return {
                ...state,
                fetchdata: false,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default Polls