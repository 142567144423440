export const TEST_DATA_FETCH_REQUEST = 'TEST_DATA_FETCH_REQUEST';
export const TEST_DATA_FETCH_SUCCESS = 'TEST_DATA_FETCH_SUCCESS';
export const TEST_DATA_FETCH_FAILURE = 'TEST_DATA_FETCH_FAILURE';


export const QUESTION_REQUEST = 'QUESTION_REQUEST';
export const QUESTION_SUCCESS = 'QUESTION_SUCCESS';
export const QUESTION_FAILURE = 'QUESTION_FAILURE';


export const NEXT_STEP_REQUEST = 'NEXT_STEP_REQUEST';
export const NEXT_STEP_SUCCESS = 'NEXT_STEP_SUCCESS';
export const NEXT_STEP_FAILURE = 'NEXT_STEP_FAILURE';
