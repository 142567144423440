import React, { useState, useEffect } from 'react';
import '../Style/Newsfeed.css';
import { BiLike } from "react-icons/bi";
import { IoShareSocialOutline } from "react-icons/io5";

function NewsFeed({ newsfeed, onNewsClick }) {

  const [likedNews, setLikedNews] = useState(new Set());
  const [newsfeedData, setnewfeedData] = useState([])
  const [expandedNews, setExpandedNews] = useState({});


  useEffect(() => {
    if (newsfeed !== undefined) {
      setnewfeedData(newsfeed)
    }
  }, [newsfeed])

  // console.log('newsfeed', newsfeed)

  const toggleLike = (event, newsId) => {
    event.stopPropagation();
    setLikedNews(prevLikedNews => {
      const newLikedNews = new Set(prevLikedNews);
      if (newLikedNews.has(newsId)) {
        newLikedNews.delete(newsId);
      } else {
        newLikedNews.add(newsId);
      }
      return newLikedNews;
    });
  };

  const onCardClicked = (news) => {
    onNewsClick(news);
    // Handle card click logic
  };

  const toggleReadMore = (index) => {
    setExpandedNews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div>
      <div className="newsfeed-cards-main">
        {newsfeedData.map((newsItem, index) => (
          <div className="card news-card-main" key={index}>
            <img className="news-thumbnail-main" src={newsItem.thumbnail_url} alt={newsItem.title} />
            <div className="news-content-main">
              <div style={{ minHeight: '55px' }}>
                <span className="news-title-main">{newsItem.name}</span>
              </div>
              <div className="news-description-container-main">
                <p className="news-description-main">
                  {expandedNews[index] ? newsItem.descrption : `${newsItem.short_descrption.slice(0, 50)}...`}
                  <button className="read-more-button-main" onClick={() => toggleReadMore(index)}>
                    {expandedNews[index] ? 'Read Less' : 'Read More'}
                  </button>
                </p>
              </div>
              <div className="news-footer-main">
                <div className="like-button-main" onClick={toggleLike}>
                  <div>
                    <BiLike size={20} />
                  </div>
                  <span>Like</span>
                </div>
                <div className="share-button-main">
                  <div>
                    <IoShareSocialOutline size={20} />
                  </div>
                  <span>Share</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewsFeed;
