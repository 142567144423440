import React, { useEffect, useState } from 'react'

function BannerTwo({
    Bannerdata,
}) {
    const [bannerimage, setbannerimage] = useState()
    const location = 2

    useEffect(() => {
        if (Bannerdata !== undefined) {
            const finallocation = Bannerdata[0].Banners.filter((loca) => loca.location === location)
            setbannerimage(finallocation)
        }
    }, [Bannerdata])
    // console.log('bannerimage', bannerimage)

    // console.log('finalimage', Bannerdata)

    const imageUrl = bannerimage && bannerimage.length > 0 && bannerimage[0] ? bannerimage[0].thumbnail_url : "";
    return (
        <div>
            {imageUrl && (
                <img style={{ marginBottom: '5%' }} src={imageUrl} width="100%" alt='banner' />
            )}
        </div>
    )
}

export default BannerTwo