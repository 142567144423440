import React, { Component } from 'react'
import Case from '../../Component/Pages/Case'

export class Cases extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        const {casesRequest} = this.props
        casesRequest()
    }
  render() {
    const {casedata, isLoading} = this.props
    return (
      <div>
        <Case
          casedata={casedata}
          isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Cases