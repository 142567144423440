import React, { Component } from 'react'
import Modules from '../../Component/Pages/Modules'
import Modal from '../../common/Modal'

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: false,
      videoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      isVideoPlaying: false
    };
    this.videoRef = React.createRef();
  }
  componentDidMount() {
    const { modulerequest } = this.props;
    modulerequest();
  }

  onclickendroll = () => {
    this.setState({
      showmodal: true
    })
  }
  closemodal = () => {
    this.setState({
      showmodal: false
    })
  }
  handleVideoPlay = () => {
    this.setState({ isVideoPlaying: true });
  }

  render() {
    const { fecheddata, modules } = this.props
    const { showmodal, videoUrl, isVideoPlaying } = this.state
    return (
      <div>
        <Modules
          fecheddata={fecheddata}
          modules={modules}
          onclickendroll={this.onclickendroll}
        />
        <Modal isOpen={showmodal} onClose={this.closemodal}>
          <div style={{ width: '100%' }}>
            {isVideoPlaying ? (
              <video
                width="100%"
                controls
                autoPlay
                onPlay={() => this.setState({ isVideoPlaying: true })}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div>
                <p>Click below to play the video:</p>
                <button onClick={this.handleVideoPlay}>Play Video</button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    )
  }
}

export default Module
