import React, { Component } from 'react'
import Resources from '../../Component/Pages/Resources'

export class Resource extends Component {

    componentDidMount() {
        const { resourcerequest } = this.props
        resourcerequest()
    }
    render() {
        const {resourcedata, isLoading} = this.props
        return (
            <div>
                <Resources
                    resourcedata={resourcedata}
                    isLoading={isLoading}
                />
            </div>
        )
    }
}

export default Resource
