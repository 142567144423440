import React, { Component } from 'react'
import Quiz from '../../Component/Pages/Quiz';

export class Quize extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        const {quizerequest} = this.props
        quizerequest()
    }

    componentDidUpdate(){}
  render() {

    const {quize, isLoading} = this.props
    return (
      <div>
        <Quiz
            quize={quize}
            isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Quize
