import React, { Component } from 'react'
import Poll from '../../Component/Pages/Poll'

export default class Polls extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { pollsrequest } = this.props
        pollsrequest()
    }
    render() {
        const {pollsdata, isLoading} = this.props

        return (
            <div>
                <Poll
                    Polls={pollsdata}
                    isLoading={isLoading}
                />
            </div>
        )
    }
}
