import {
  TEST_DATA_FETCH_REQUEST,
  TEST_DATA_FETCH_SUCCESS,
  TEST_DATA_FETCH_FAILURE,
  QUESTION_REQUEST,
  QUESTION_SUCCESS,
  QUESTION_FAILURE,
  NEXT_STEP_REQUEST,
  NEXT_STEP_SUCCESS,
  NEXT_STEP_FAILURE
} from "./actionType";

const initialState = {
  fetchdata: false,
  Test: [],
  Testerror: null,
  isLoading: false,

  isQuestionLoading: false,
  submitQuestion: false,
  Questions: [],
  QuestionError: null,

  Nextsteploading :false,
  NextstepResult: [],
  NextstepError:null
};

const Test = (state = initialState, action) => {
  switch (action.type) {
    case TEST_DATA_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TEST_DATA_FETCH_SUCCESS:
      return {
        ...state,
        fetchdata: true,
        Test: action.payload,
        Testerror: null,
        isLoading: false,
      };
    case TEST_DATA_FETCH_FAILURE:
      return {
        ...state,
        fetchdata: false,
        Testerror: action.payload,
        isLoading: false,
      };
    case QUESTION_REQUEST:
      return {
        ...state,
        isQuestionLoading: true,
      };
    case QUESTION_SUCCESS:
      return {
        ...state,
        isQuestionLoading: false,
        submitQuestion: true,
        Questions: action.payload,
        QuestionError: null,
      };
    case QUESTION_FAILURE:
      return {
        ...state,
        isQuestionLoading: false,
        submitQuestion: false,
        QuestionError: action.payload,
      };
      case NEXT_STEP_REQUEST:
      return {
        ...state,
        Nextsteploading: true,
      };
    case NEXT_STEP_SUCCESS:
      return {
        ...state,
        NextstepResult: action.payload,
        NextstepError: null,
        Nextsteploading: false,
      };
    case NEXT_STEP_FAILURE:
      return {
        ...state,
        NextstepError: action.payload,
        Nextsteploading: false,
      };
    default:
      return state;
  }
};

export default Test;
