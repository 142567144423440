import { connect } from "react-redux";
import Resource from "./Resource";
import { withNavigation } from "../../common/withNavigation";
import { resourcefetchrequest } from "./action";


const mapStateToProps = (state) => {
    const { Resource } = state;
console.log('resource---->', Resource.resource.data)
    return {
        ...state,
        resourcedata: Resource.resource.data,
        // // fecheddata :course.fetchdata,
        isLoading: Resource.isLoading,
        // // error: course.error,
        // // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resourcerequest: () => dispatch(resourcefetchrequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Resource));