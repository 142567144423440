import { CASES_FETCH_REQUEST, CASES_FETCH_SUCCESS, CASES_FETCH_FAILURE } from "./actionType";


export const casesRequest = (params) =>({
    type : CASES_FETCH_REQUEST,
    payload : params,
});

export const casesRequestsuccess = (data) =>({
    type : CASES_FETCH_SUCCESS,
    payload : data
});


export const casesRequestfailure = (error) => ({
    type : CASES_FETCH_FAILURE,
    payload: error
})