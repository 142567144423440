import {connect} from 'react-redux'
import Program from './Program';
import { withNavigation } from '../../common/withNavigation';
import { programRequest } from './action';
import { Testfetchrequest } from "../Test/action";


const mapStateToProps = (state) => {
    // console.log('Program', state.Program.Program.data)
      return {
        Programdata:state.Program.Program.data
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        programRequest: ()=> (dispatch(programRequest())),
        testrequest : (params) =>(dispatch(Testfetchrequest(params)))
      }
    };
  
    export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Program));