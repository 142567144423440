import { WEBINAR_DATA_REQUEST, WEBINAR_DATA_SUCCESS, WEBINAR_DATA_FAILURE } from "./actionType";

const initialState = {
    fetchdata: false,
    webinar: [],
    error: null,
    isLoading: false
};


const webinar = (state = initialState, action) =>{
    switch(action.type){
        case WEBINAR_DATA_REQUEST:
            return {
                ...state, 
                isLoading: true,
            }
            case WEBINAR_DATA_SUCCESS :
                return {
                    ...state,
                    fetchdata: true,
                    webinar: action.payload,
                    error: null,
                    isLoading : false
                }
                case WEBINAR_DATA_FAILURE:
                    return {
                        ...state,
                        fetchdata: false,
                        error : action.payload,
                        isLoading: false
                    };
                    default :
                    return state;
    }
}
export default webinar