import { GET_PROGRAM_REQUEST, GET_PROGRAM_SUCCESS, GET_PROGRAM_FAILURE } from "./actionType"

export const programRequest = (credentials) => ({
    type: GET_PROGRAM_REQUEST,
    payload: credentials,
  });
  
  export const programSuccess = (data) => ({
    type: GET_PROGRAM_SUCCESS,
    payload: data,
  });
  
  export const programFailure = (error) => ({
    type: GET_PROGRAM_FAILURE,
    payload: error,
  });