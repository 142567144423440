import { MODULE_REQUEST, MODULE_FETCH_SUCCESS, MODULE_FETCH_FAILURE } from "./actionType";

const initialState = {
    fetchdata: false,
    module: { data: [] },
    error: null,
    isLoading: false
};

const modulereducer = (state = initialState, action) =>{
    switch(action.type){
        case MODULE_REQUEST:
            return {...state, isLoading: true}
            case MODULE_FETCH_SUCCESS :
                return {
                    ...state,
                    fetchdata: true,
                    module: action.payload,
                    error: null,
                    isLoading : false
                }
                case MODULE_FETCH_FAILURE:
                    return {
                        ...state,
                        fetchdata: false,
                        error : action.payload,
                        isLoading: false
                    };
                    default :
                    return state;
    }
}

export default modulereducer