import { connect } from "react-redux";
import Quize from "./Quize";
import { withNavigation } from "../../common/withNavigation";
import { quizefetchrequest } from "./action";


const mapStateToProps = (state) => {
    const { quize } = state;
    return {
        ...state,
        quize: quize.quize.data,
        // fecheddata :course.fetchdata,
        isLoading: quize.isLoading,
        // error: course.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        quizerequest: () => dispatch(quizefetchrequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Quize));