import React, { useEffect, useState } from 'react'
// import banner from '../../assets/banner.png'
import ImageCarousel from '../../common/ImageCarousel'

function Banner({
  BannerData
}) {

  const [bannerimage, setbannerimage] = useState()


  useEffect(() => {
    if (BannerData !== undefined) {
      const location = 1
      const finallocation = BannerData.filter((loca) => loca.location === location)
      setbannerimage(finallocation[0])
    }
  }, [BannerData])

  // console.log('BannerData', BannerData)
  return (
    <>
      {bannerimage && (
        <div>
          <img src={bannerimage ? `${bannerimage.thumbnail_url}` : "https://placehold.co/1040x250"} width="100%" alt='banner' />
        </div>
      )}
    </>
  )
}

export default Banner
