import { WEBINAR_DATA_REQUEST, WEBINAR_DATA_SUCCESS, WEBINAR_DATA_FAILURE } from "./actionType"


export const WebinarRequest = (params) =>({
    type : WEBINAR_DATA_REQUEST,
    payload : params,
});

export const WebinarRequestsuccess = (data) =>({
    type : WEBINAR_DATA_SUCCESS,
    payload : data
});


export const WebinarRequestfailure = (error) => ({
    type : WEBINAR_DATA_FAILURE,
    payload: error
})