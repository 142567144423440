import { put, takeEvery, call } from 'redux-saga/effects';
import { NEWS_FEED_REQUEST } from './actionTypes';
import { newsfeedfetchsuccess, newsfeedfetchfailure } from './action';
import axiosInstance from '../../common/AxiosConfig';

function* newsfeedSaga(action) {
    try {
        const response = yield call(axiosInstance.get, `/external/home/newsfeed`, {
            params: action.payload
        });
        yield put(newsfeedfetchsuccess(response.data));
    } catch (error) {
        yield put(newsfeedfetchfailure(error.message));
    }
}

export function* newsrootSaga() {
    yield takeEvery(NEWS_FEED_REQUEST, newsfeedSaga);
}
