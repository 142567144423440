import { put, takeEvery, call } from "redux-saga/effects";
import { GET_PROGRAM_REQUEST } from "./actionType";
import { programSuccess, programFailure } from "./action";
import axiosInstance from "../../common/AxiosConfig";

function* programSaga(action) {
  try {
    const response = yield call(axiosInstance.post, `/external/program`, {
      params: action.payload,
    });
    yield put(programSuccess(response.data));
  } catch (error) {
    yield put(programFailure(error.message));
  }
}

export function* modulerProgramSaga() {
  yield takeEvery(GET_PROGRAM_REQUEST, programSaga);
}
