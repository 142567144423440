import React, { Component } from 'react'
import Course from '../../Component/Pages/Course'

export class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { courserequest } = this.props
        courserequest()
    }
    render() {
        const { course, isLoading } = this.props
        return (
            <div>
                <Course
                    course={course}
                    isLoading={isLoading}
                />
            </div>
        )
    }
}

export default Courses
