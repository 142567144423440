import React, { useState, useEffect } from 'react';
import './Sidenavbar.css';
import signal from '../../assets/signal.png';
import Vector from '../../assets/vector.png'
import file from '../../assets/file.png'
import setting from '../../assets/setting.png'
import feed from '../../assets/feed.png'
import Window from '../../assets/window.png'
import { useLocation } from 'react-router-dom';


const navItems = [
    { label: 'NewsFeed', href: '/newsfeed', img: signal },
    { label: 'Modules', href: '/modules', img: Vector },
    { label: 'Courses', href: '/course', img: file },
    { label: 'Cases', href: '/case', img: feed },
    { label: 'Webinars', href: '/webinar', img: Window },
    { label: 'Quiz', href: '/quiz', img: setting },
    { label: 'Polls', href: '/poll', img: setting },
    { label: 'Resources', href: '/resource', img: setting },
    { label: 'Games', href: '/games', img: setting },

];

const Sidenavbar = () => {
    const location = useLocation();
    const getActiveIndex = () => {
        return navItems.findIndex(item => item.href === location.pathname);
    };
    const [activeIndex, setActiveIndex] = useState(getActiveIndex());
    useEffect(() => {
        setActiveIndex(getActiveIndex());
    }, [location]);

    return (
        <div className="main">
            <div className="sidenav">
                <div className="sidenav-list">
                    {navItems.map((item, index) => (
                        <div
                            key={index}
                            className={`nav-list ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => setActiveIndex(index)}
                        >
                            <img src={item.img} alt="icon" />
                            <a style={{ width:'100%' }} href={item.href}>
                                <div>{item.label}</div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="help">
                <div className="help-text">
                    <div>
                        <img
                            className="circle"
                            src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/Question.png"
                            alt="question"
                        />
                    </div>
                    <div className="help-center">Help Center</div>
                    <div className="help-center-sub">
                        Having Trouble in Learning. Please contact us for more questions.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidenavbar;
