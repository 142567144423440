import {
  TEST_DATA_FETCH_REQUEST,
  TEST_DATA_FETCH_SUCCESS,
  TEST_DATA_FETCH_FAILURE,
  QUESTION_REQUEST,
  QUESTION_SUCCESS,
  QUESTION_FAILURE,
  NEXT_STEP_REQUEST,
  NEXT_STEP_SUCCESS,
  NEXT_STEP_FAILURE,
} from "./actionType";

export const Testfetchrequest = (params) => ({
  type: TEST_DATA_FETCH_REQUEST,
  payload: params,
});

export const Testfetchsuccess = (data) => ({
  type: TEST_DATA_FETCH_SUCCESS,
  payload: data,
});

export const Testfetchfailure = (error) => ({
  type: TEST_DATA_FETCH_FAILURE,
  payload: error,
});

export const Questionrequest = (params) => ({
  type: QUESTION_REQUEST,
  payload: params,
});

export const Questionsuccess = (data) => ({
  type: QUESTION_SUCCESS,
  payload: data,
});

export const Questionfailure = (error) => ({
  type: QUESTION_FAILURE,
  payload: error,
});


export const Nextsteprequest = (params) => ({
  type: NEXT_STEP_REQUEST,
  payload: params,
});

export const Nextstepsuccess = (data) => ({
  type: NEXT_STEP_SUCCESS,
  payload: data,
});

export const Nextstepfailure = (error) => ({
  type: NEXT_STEP_FAILURE,
  payload: error,
});
