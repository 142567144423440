import { connect } from "react-redux";
import Courses from "./Courses";
import { withNavigation } from "../../common/withNavigation";
import { coursesRequest } from "./action";


const mapStateToProps = (state) => {
    const { course } = state;
    return {
        ...state,
        course: course.course.data,
        fecheddata :course.fetchdata,
        isLoading: course.isLoading,
        error: course.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        courserequest: () => dispatch(coursesRequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Courses));