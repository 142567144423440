import { RESOURCE_FETCH_REQUEST, RESOURCE_FETCH_SUCCESS, RESOURCE_FETCH_FAILURE } from "./actionType";



export const resourcefetchrequest = (params) => ({
    type: RESOURCE_FETCH_REQUEST,
    payload: params
});

export const resourcefetchsuccess = (data) => ({
    type: RESOURCE_FETCH_SUCCESS,
    payload: data
});

export const resourcefetchfailure = (error) => ({
    type: RESOURCE_FETCH_FAILURE,
    payload: error
});