import { QUIZE_DATA_FETCH_REQUEST, QUIZE_DATA_FETCH_SUCCESS, QUIZE_DATA_FETCH_FAILURE } from "./actionType";


export const quizefetchrequest = (params) => ({
    type: QUIZE_DATA_FETCH_REQUEST,
    payload: params
});

export const quizefetchsuccess = (data) => ({
    type: QUIZE_DATA_FETCH_SUCCESS,
    payload: data
});

export const quizefetchfailure = (error) => ({
    type: QUIZE_DATA_FETCH_FAILURE,
    payload: error
});