import React, { useState, useEffect } from "react";
import "../Style/Case.css";

function Case({ casedata, isLoading }) {
  const [caseData, setcaseData] = useState([]);

  useEffect(() => {
    if (casedata !== undefined) {
      setcaseData(casedata);
    }
  }, [casedata]);

  // console.log(casedata)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="case-container">
        {caseData.length > 0 ? (
          caseData.map((item) => (
            <div key={item.id} className="case-card">
              <img
                src={item.thumbnail_url}
                alt={item.module_title}
                className="case-image"
              />
              <div className="case-details">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    minHeight: "58px",
                  }}
                >
                  {item.module_title}
                </p>
                <button className="case-button">
                  {item.meeting === 1 ? "RSVP" : "Scheduled Meeting"}
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No Cases Available</p>
        )}
      </div>
    </>
  );
}

export default Case;
