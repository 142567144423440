import { MODULE_FETCH_SUCCESS,MODULE_REQUEST,MODULE_FETCH_FAILURE } from "./actionType";

export const modulerequest = (params) =>({
    type : MODULE_REQUEST,
    payload : params,
})


export const modulefetchsuccess = (data) =>({
    type : MODULE_FETCH_SUCCESS,
    payload : data,
})

export const modulefetchfailure = (error)=>({
    type : MODULE_FETCH_FAILURE,
    payload : error,

})