import { connect } from 'react-redux';
import { newsfeedrequest } from './action';
import { withNavigation } from '../../common/withNavigation';
import NewsFeed from './NewsFeed';



const mapStateToProps = (state) => {
    const newsFeedData = state.newsfeed.newsFeed.data;
    return {
        newsFeed: newsFeedData,
        isLoading: state.newsfeed.isLoading,
        error: state.newsfeed.error,
        fetchdata: state.newsfeed.fetchdata
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newsfeedrequest: (params) => dispatch(newsfeedrequest(params))
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(NewsFeed));