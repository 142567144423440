import { put, takeEvery, call } from 'redux-saga/effects';
import axiosInstance from '../../common/AxiosConfig';
import { WEBINAR_DATA_REQUEST } from './actionType';
import { WebinarRequestsuccess, WebinarRequestfailure } from './action';

function* WebinarSaga(action) {
    try {
        const response = yield call(axiosInstance.get, `/external/home/webinar`, {
            params: action.payload
        });
        yield put(WebinarRequestsuccess(response.data));
    } catch (error) {
        yield put(WebinarRequestfailure(error.message));
    }
}

export function* WebinarrootSaga() {
    yield takeEvery(WEBINAR_DATA_REQUEST, WebinarSaga);
}
