import { connect } from "react-redux";
import Webinars from "./Webinars";
import { withNavigation } from "../../common/withNavigation";
import { WebinarRequest } from "./action";


const mapStateToProps = (state) => {
    const { webinar } = state;

    // console.log('webinar', webinar.webinar.data)
    return {
        ...state,
        webinar: webinar.webinar.data,
        // fecheddata :webinar.fetchdata,
        isLoading: webinar.isLoading,
        // error: course.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        WebinarRequest: () => dispatch(WebinarRequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Webinars));