import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  BANNER_REQUEST,
  BANNER_SUCCESS,
  BANNER_FAILURE
} from "./actionType";



export const loginRequest = (credentials) => ({
    type: LOGIN_REQUEST,
    payload: credentials,
  });
  
  export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    payload: data,
  });
  
  export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
  });


  export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
  });
  
  export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
  });
  
  export const logoutFailure = (error) => ({
    type: LOGOUT_FAILURE,
    payload: error,
  });


  export const BannerRequest = (data) => ({
    type: BANNER_REQUEST,
    payload:data
  });
  
  export const BannerSuccess = (data) => ({
    type: BANNER_SUCCESS,
    payload:data
  });
  
  export const BannerFailure = (error) => ({
    type: BANNER_FAILURE,
    payload: error,
  });