import { put, takeEvery, call } from 'redux-saga/effects';
import { QUIZE_DATA_FETCH_REQUEST } from './actionType';
import { quizefetchsuccess, quizefetchfailure } from './action';
import axiosInstance from '../../common/AxiosConfig';

function* quizSaga(action) {
    try {
        const response = yield call(axiosInstance.get, `/external/home/quiz`, {
            params: action.payload
        });
        yield put(quizefetchsuccess(response.data));
    } catch (error) {
        yield put(quizefetchfailure(error.message));
    }
}

export function* quizrootSaga() {
    yield takeEvery(QUIZE_DATA_FETCH_REQUEST, quizSaga);
}
