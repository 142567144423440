import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  BANNER_REQUEST,
  BANNER_SUCCESS,
  BANNER_FAILURE
} from "./actionType";


const initialState = {
  isAuthenticated: false,
  Loading: false,
  user: [],
  token: null,
  error: null,

  isbannerLoading:false,
  banner:[],
  isbannerError:null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated:false,
        Loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated:true,
        Loading: false,
        user: action.payload,
        token: action.payload.token,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated:false,
        Loading: false,
        error: action.payload,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated:true,
        Loading: true,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated:false,
        Loading: false,
        user: null,
        token: null,
        error: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isAuthenticated:true,
        Loading: false,
        error: action.payload,
      };

      case BANNER_REQUEST: 
      return{
        ...state,
        isbannerLoading:true,
        isbannerError:false,
      }
      case BANNER_SUCCESS:
        return {
          ...state,
          isbannerLoading:false,
          banner:action.payload,
          isbannerError:false,
        }
        case BANNER_FAILURE:
          return{
            ...state,
            isbannerLoading:false,
            isbannerError:action.payload,
          }
    default:
      return state;
  }
}

export default login