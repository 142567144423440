import React, { Component } from 'react'
import Webinar from '../../Component/Pages/Webinar';

export class Webinars extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        const {WebinarRequest} = this.props
        WebinarRequest()
    }
  render() {
    const {webinar,isLoading } = this.props
    return (
      <div>
        <Webinar
         webinar={webinar}
         isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Webinars