import React, { useState, useEffect } from 'react';
import '../Style/Resource.css'

function Resources({ resourcedata, isLoading }) {
    const [resourceData, setResourceData] = useState([]);

    useEffect(() => {
        if (resourcedata !== undefined) {
            setResourceData(resourcedata);
        }
    }, [resourcedata]);

    if (isLoading) {
        return <p>Loading resources...</p>;
    }

    // console.log('resourceData',resourceData)

    return (
        <>
            <div className="resource-grid-container">
                {resourceData.map((resource) => (
                    <div key={resource.id} className="resource-card">
                        <img
                            src={resource.thumbnail}
                            alt={resource.resource_title}
                            className="resource-image"
                        />
                        <div>
                            <span style={{ fontWeight:'bold' }}>{resource.resource_title}</span>
                        </div>
                        <p>Discover health insurance options tailored for you. Explore now.</p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Resources;
