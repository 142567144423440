import { connect } from "react-redux";
import { withNavigation } from "../../common/withNavigation";
import { casesRequest } from "./action";
import Cases from "./Cases";



const mapStateToProps = (state) => {
    const { cases } = state;
    // console.log('case---->', cases)
    return {
        ...state,
        casedata: cases.case.data,
        // fecheddata :course.fetchdata,
        isLoading: cases.isLoading,
        // error: course.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        casesRequest: () => dispatch(casesRequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Cases));