import { NEWS_FEED_REQUEST, NEWS_FEED_FETCH_FAILURE, NEWS_FEED_FETCH_SUCCESS } from './actionTypes';

export const newsfeedrequest = (params) => ({
    type: NEWS_FEED_REQUEST,
    payload: params
});

export const newsfeedfetchsuccess = (data) => ({
    type: NEWS_FEED_FETCH_SUCCESS,
    payload: data
});

export const newsfeedfetchfailure = (error) => ({
    type: NEWS_FEED_FETCH_FAILURE,
    payload: error
});
