import { COURSES_FETCH_REQUEST, COURSES_FETCH_SUCCESS, COURSES_FETCH_FAILURE } from "./actionType";

const initialState = {
    fetchdata: false,
    course: { data: [] },
    error: null,
    isLoading: false
};


const course = (state = initialState, action) =>{
    switch(action.type){
        case COURSES_FETCH_REQUEST:
            return {
                ...state, 
                isLoading: true,
            }
            case COURSES_FETCH_SUCCESS :
                return {
                    ...state,
                    fetchdata: true,
                    course: action.payload,
                    error: null,
                    isLoading : false
                }
                case COURSES_FETCH_FAILURE:
                    return {
                        ...state,
                        fetchdata: false,
                        error : action.payload,
                        isLoading: false
                    };
                    default :
                    return state;
    }
}
export default course