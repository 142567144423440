import {RESOURCE_FETCH_REQUEST, RESOURCE_FETCH_SUCCESS, RESOURCE_FETCH_FAILURE } from "./actionType";

const initialState = {
    fetchdata: false,
    resource:[],
    error: null,
    isLoading: false
};

const Resource = (state = initialState, action) => {
    switch(action.type) {
        case RESOURCE_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case RESOURCE_FETCH_SUCCESS:
            return {
                ...state,
                fetchdata: true,
                resource: action.payload,
                error: null,
                isLoading: false
            };
        case RESOURCE_FETCH_FAILURE:
            return {
                ...state,
                fetchdata: false,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default Resource;
