import axios from 'axios';
import store from '../Store/Store';
import { Api } from './Api';

const axiosInstance = axios.create({
  baseURL: Api.Apiurl
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = store.getState().login;
    const storedToken = localStorage.getItem('token');
    const finalToken = token || storedToken;
    if (finalToken) {
      config.headers['Authorization'] = `Bearer ${finalToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
