import React from 'react'
import '../../Style/Footer.css'

function Footer() {
    return (
        <div className="footer">
            <div>
            &copy;{new Date().getFullYear()} Mahaveltech All rights reserved.
            </div>
        </div>
    )
}

export default Footer