import { POLLS_DATA_FETCH_REQUEST, POLLS_DATA_FETCH_SUCCESS, POLLS_DATA_FETCH_FAILTURE } from "./actionType";


export const pollsfetchrequest = (params) => ({
    type: POLLS_DATA_FETCH_REQUEST,
    payload: params
});

export const pollsfetchsuccess = (data) => ({
    type: POLLS_DATA_FETCH_SUCCESS,
    payload: data
});

export const pollsfetchfailure = (error) => ({
    type: POLLS_DATA_FETCH_FAILTURE,
    payload: error
});