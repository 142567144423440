import { connect } from 'react-redux';
import { modulerequest } from './action';
import Module from './Module';
import { withNavigation } from '../../common/withNavigation';

const mapStateToProps = (state) => {
    const { module } = state;
    // console.log('module', module.module.data)
    return {
        modules: module.module.data,
        fecheddata :module.fetchdata
        // isLoading: module.isLoading,
        // error: module.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modulerequest: () => dispatch(modulerequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Module));
