import React from 'react';
import '../Style/Login.css'
import { FaArrowRight } from "react-icons/fa";

const Login = ({
    email,
    password,
    onclicklogin,
    handleChange
}) => {
    return (
        <div className="container-fluid">
            <div className="login-cont">
                <div className="col">
                    <img className="doctor" src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/image.png" alt="Description" />
                </div>
                <div className="col login-container">
                    <div className="login-container-sub">
                        <div>
                            <img className='login-logo' src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/logo.png" alt="logo" />
                        </div>
                        <div className="sign">Sign In</div>
                        <div className="sub-text">Please fill your information below</div>
                        <form>
                            <div className="login-input-container">
                                <img src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/Profile.png" alt="Profile Icon" />
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Name"
                                    value={email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <br />
                            <div className="login-input-container">
                                <img src="https://falcon-asset.s3.ap-south-1.amazonaws.com/admin-ui/passoword.png" alt="Password Icon" />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <br />
                            <div className='last'>
                                <div className="forgot">
                                    Forgot Password?
                                </div>
                                <div>
                                    <button onClick={onclicklogin} className='login-button' type="button">Login <span className="arrow">&nbsp;<FaArrowRight size={15}/></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
