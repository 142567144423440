export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


export const BANNER_REQUEST = 'BANNER_REQUEST';
export const BANNER_SUCCESS = 'BANNER_SUCCESS';
export const BANNER_FAILURE = 'BANNER_FAILURE';
