import { put, takeEvery, call } from 'redux-saga/effects';
import { MODULE_REQUEST } from './actionType';
import { modulefetchsuccess, modulefetchfailure } from './action';
import axiosInstance from '../../common/AxiosConfig';

function* moduleSaga(action) {
    // const response = yield call(axios.get, `${Api.Apiurl}/external/home/module`, {
    //     params: action.payload
    // });
    // console.log('response',response)
    try {
        const response = yield call(axiosInstance.get, `/external/home/module`, {
            params: action.payload
        });
        yield put(modulefetchsuccess(response.data));
    } catch (error) {
        yield put(modulefetchfailure(error.message));
    }
}

export function* modulerootSaga() {
    yield takeEvery(MODULE_REQUEST, moduleSaga);
}
