import { COURSES_FETCH_SUCCESS, COURSES_FETCH_REQUEST, COURSES_FETCH_FAILURE } from "./actionType";


export const coursesRequest = (params) =>({
    type : COURSES_FETCH_REQUEST,
    payload : params,
});

export const coursesRequestsuccess = (data) =>({
    type : COURSES_FETCH_SUCCESS,
    payload : data
});


export const coursesRequestfailure = (error) => ({
    type : COURSES_FETCH_FAILURE,
    payload: error
})