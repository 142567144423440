import Login from "./Login";
import {withNavigation} from '../../common/withNavigation'
import { connect } from "react-redux";
import { loginRequest } from "./action";



const mapStateToProps = (state) => {
  console.log('login', state.login.user)
    return {
      ...state,
      user:state.login.user
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loginRequest : (params) =>(dispatch(loginRequest(params)))
    }
  };

  export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Login));