import { connect } from "react-redux";
import { withNavigation } from "../../common/withNavigation";
import { pollsfetchrequest } from "./action";
import Polls from "./Polls";


const mapStateToProps = (state) => {
    const { Polls } = state;
    console.log('pools', Polls.Poll.data)
    return {
        ...state,
        pollsdata: Polls.Poll.data,
        // // fecheddata :course.fetchdata,
        isLoading: Polls.isLoading,
        // error: course.error,
        // fetchdata: module.fetchdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pollsrequest: () => dispatch(pollsfetchrequest()),
    };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(Polls));