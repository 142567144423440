import { put, takeEvery, call } from "redux-saga/effects";
import {
  TEST_DATA_FETCH_REQUEST,
  QUESTION_REQUEST,
  NEXT_STEP_REQUEST,
} from "./actionType";
import {
  Testfetchsuccess,
  Testfetchfailure,
  Questionsuccess,
  Questionfailure,
  Nextstepfailure,
  Nextstepsuccess,
} from "./action";
import axiosInstance from "../../common/AxiosConfig";

function* TestSaga(action) {
  const id = action.payload.id;
  try {
    const response = yield call(axiosInstance.get, `/external/test/${id}`, {});
    yield put(Testfetchsuccess(response.data));
  } catch (error) {
    yield put(Testfetchfailure(error.message));
  }
}

export function* TestrootSaga() {
  yield takeEvery(TEST_DATA_FETCH_REQUEST, TestSaga);
}

function* QuestionSaga(action) {
  const id = action.payload.id;
  try {
    const response = yield call(
      axiosInstance.post,
      `/external/test/${id}`,
      action.payload.params,
      {}
    );
    yield put(Questionsuccess(response.data));
  } catch (error) {
    yield put(Questionfailure(error.message));
  }
}

export function* QuestionrootSaga() {
  yield takeEvery(QUESTION_REQUEST, QuestionSaga);
}

function* NextstepSaga(action) {
  const id = action.payload.id;
//   console.log("NextstepSaga--->", id);
  try {
    const response = yield call(axiosInstance.get,`/external/nextstep/${id}`,{}
    );
    // console.log("Nextstepsuccess-->", response.data);
    yield put(Nextstepsuccess(response.data));
  } catch (error) {
    // console.log('Nextsteperror-->', error)
    yield put(Nextstepfailure(error.message));
  }
}

export function* NextsteprootSaga() {
  yield takeEvery(NEXT_STEP_REQUEST, NextstepSaga);
}
