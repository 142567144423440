import React, { useState, useEffect } from 'react';
import '../Style/Webinar.css'

function Webinar({ webinar, isLoading }) {
  const [webinarData, setWebinarData] = useState([]);

  useEffect(() => {
    if (webinar !== undefined) {
      setWebinarData(webinar);
    }
  }, [webinar]);

  // console.log('wibinar', webinarData)

  const scheduledCount = webinarData.length;
  const rescheduledCount = 14;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="webinar-container">
      <div className="stats">
        <div className="stats-item">
          <div className="stats-icon">👥</div>
          <div>
            <h4>Scheduled Meetings</h4>
            <p>{scheduledCount} This month</p>
          </div>
        </div>
        <div className="stats-item">
          <div className="stats-icon">📅</div>
          <div>
            <h4>Rescheduled Meetings</h4>
            <p>{rescheduledCount} This month</p>
          </div>
        </div>
      </div>

      <div className="meetings">
        {webinarData.map((webinarItem, index) => (
          <div key={index} className="meeting-card">
            <img src={webinarItem.thumbnail_url} alt="Speaker" className="speaker-image" />
            <div className="meeting-header">
              <span>{webinarItem.module_name}</span>
            </div>
            <div className="meeting-info">
              <p><strong>Date:</strong> {new Date(webinarItem.startdate_time).toLocaleDateString()}</p>
              <p><strong>Time:</strong> {new Date(webinarItem.startdate_time).toLocaleTimeString()}</p>
              <p><strong>Speaker:</strong> {webinarItem.speaker_name}</p>
              <p><strong>Specialty:</strong> {webinarItem.speaker_details}</p>
            </div>
            <button className="view-details-btn">View Details</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Webinar;
