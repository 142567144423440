import { GET_PROGRAM_REQUEST, GET_PROGRAM_SUCCESS, GET_PROGRAM_FAILURE } from "./actionType";


const initialState = {
    isProgramLoading: false,
    Program: [],
    isProgramError: null
}

const Program = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROGRAM_REQUEST:
            return {
                ...state,
                isProgramLoading: true,
            }
        case GET_PROGRAM_SUCCESS:
            return {
                ...state,
                isProgramLoading: false,
                Program: action.payload,
                isProgramError: null
            }
        case GET_PROGRAM_FAILURE:
            return {
                ...state,
                isProgramLoading: false,
                isProgramError: action.payload
            }
        default:
            return state
    }
}

export default Program