import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { newsfeedrequest } from './action';
import Newsfeed from '../../Component/Pages/Newsfeed';
import Modal from '../../common/Modal';

class NewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNews: null,
        };
    }
    componentDidMount() {
        this.props.newsfeedrequest();
    }

    handleNewsClick = (news) => {
        this.setState({ selectedNews: news });
    };

    handleCloseModal = () => {
        this.setState({ selectedNews: null });
    };

    render() {
        const { newsFeed, isLoading, error } = this.props;
        const { selectedNews } = this.state;
        return (
            <div>
                {isLoading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {newsFeed &&
                    <div>
                        <Newsfeed
                            newsfeed={newsFeed}
                            onNewsClick={this.handleNewsClick}
                        />
                    </div>
                }
                <Modal
                    isOpen={!!selectedNews} // Show modal if there's selected news
                    onClose={this.handleCloseModal} // Pass down the close handler
                >
                    {selectedNews && (
                        <div>
                            <h2>{selectedNews.title}</h2>
                            <img
                                src={selectedNews.thumbnail}
                                alt={selectedNews.title}
                                style={{ width: '50%', height: '20%' }}
                            />
                            <p>{selectedNews.descrption}</p>
                            <p>{new Date(selectedNews.date).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

export default NewsFeed;
