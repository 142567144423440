import { connect } from "react-redux";
import Test from "./Test";
import { withNavigation } from "../../common/withNavigation";
import { Testfetchrequest, Questionrequest, Nextsteprequest } from "./action";

const mapStateToProps = (state) => {
//   console.log('teststate-->><<<--',state.Test.NextstepResult)
  return {
    ...state,
    Testdata: state.Test.Test.data,
    Answermessage: state.Test.Questions,
    NextstepResult:state.Test.NextstepResult
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    TestRequest: (params) => dispatch(Testfetchrequest(params)),
    QuestionRequest: (params) => dispatch(Questionrequest(params)),
    NextStepRequest:(params) =>(dispatch(Nextsteprequest(params)))
  };
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Test)
);
