import React, { Component } from "react";
import Testpage from "../../Component/Pages/Test";
import Modal from "../../common/Modal";
import Lottie from "lottie-react";
import successaimation from "../../assets/success-animation.json";

export class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlId: "",
      item: {},
      successmsg: "",
      isModalOpen: false,
      userscore: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const { item } = location.state || {};
    this.setState({
      urlId: id,
      item,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      Answermessage,
      TestRequest,
      NextStepRequest,
      NextstepResult,
      navigate,
    } = this.props;
    const { urlId, item } = this.state;
    if (prevProps.Answermessage !== Answermessage) {
      console.log("Answermessage--->", Answermessage);
      if (Answermessage.success === 1) {
        this.setState({
          isModalOpen: true,
          successmsg: Answermessage.message,
          userscore: Answermessage.score,
        });
        TestRequest({
          id: urlId,
        });
        NextStepRequest({
          id: item.program_id,
        });
      }
    }
    if (prevProps.NextstepResult !== NextstepResult) {
      if (NextstepResult.success === 1) {
        if (NextstepResult.data.length > 0) {
          navigate(NextstepResult.data[0].route);
        }
      }
    }
  }

  onclickNext = () => {
    const { NextstepResult, navigate } = this.props;
    const nextStep = NextstepResult.data[0];
    if (NextstepResult.data.length > 0) {
      navigate(nextStep.route);
    }
  };
  Modalclose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const {
      TestRequest,
      Testdata,
      QuestionRequest,
      NextStepRequest,
      NextstepResult,
    } = this.props;
    const { item, isModalOpen, successmsg, userscore } = this.state;
    return (
      <div>
        <Testpage
          TestRequest={TestRequest}
          Testdata={Testdata}
          QuestionRequest={QuestionRequest}
          item={item}
          onclickNext={this.onclickNext}
          NextStepRequest={NextStepRequest}
          NextstepResult={NextstepResult}
        />
        <Modal isOpen={isModalOpen} onClose={this.Modalclose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Lottie
              animationData={successaimation}
              loop={true}
              autoplay={true}
              style={{ height: "100px", width: "100px" }}
            />
          </div>
          <p style={{ fontWeight: "bold" }}>{successmsg}</p>
          <p style={{ textAlign: "center" }}>
            Your Scored {parseInt(userscore)}%
          </p>
          <div style={{ textAlign: "center" }}>
            <button className="next-btn" onClick={this.onclickNext}>
              Next
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Test;
